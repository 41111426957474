export const PRODUCT_BASE_URL = '/product';
export const CATALOG_BASE_URL = '/catalog';
export const CONTACT_BASE_URL = '/contact';
export const POSTS_BASE_URL = '/posts';
export const POST_BASE_URL = '/post';

export const QUERY_VARIABLES_KEYS = {
    CATEGORY_ID_KEY: 'categoryId',
    SUBCATEGORY_ID_KEY: 'subcategoryId',
    CURRENT_PAGE_NUMBER_KEY: 'currentPageNumber',
    SEARCH_PHRASE_KEY: 'searchPhrase',
    SORT_CRITERIA_KEY: 'sortCriteria',
    SALE_KEY: 'sale',
    LATEST_KEY: 'latest',
};

export const SORT_CRITERIA_VALUES = {
    CREATED_AT_DESC: 'createdAt_DESC',
    CREATED_AT_ASC: 'createdAt_ASC',
    PRICE_ASC: 'price_ASC',
    PRICE_DES: 'price_DESC',
};

export const POLISH_CURRENCY = 'zł';

export const NEW_INDICATOR_TITLE = 'Nowość!';
export const DISCOUNT_INDICATOR_TITLE = 'NOWA CENA';
