import React, { FunctionComponent, ReactNode, useLayoutEffect } from 'react';
import Layout from './layout';
import GlobalStyle from '../../styles/global-styles';
import { handleFirstTab } from '../../utils/utils';

interface Props {
    children: ReactNode;
}

const LayoutWithProtection: FunctionComponent<Props> = ({ children }) => {
    useLayoutEffect(() => {
        window.addEventListener('keydown', handleFirstTab);
        return () => window.removeEventListener('keydown', handleFirstTab);
    });

    return (
        <>
            <GlobalStyle />
            <Layout>{children}</Layout>
        </>
    );
};

export default LayoutWithProtection;
