import React, { ComponentType } from 'react';
import AuthPage from '../pages/auth-page';
import { ProtectedViewContext } from '../context-providers/protection-provider';

const withProtection = <T,>(Component: ComponentType<T>): ComponentType<T> => (props: T) => (
    <ProtectedViewContext.Consumer>
        {(value: ProtectedViewContext | null) => (value?.auth ? <Component {...props} /> : <AuthPage />)}
    </ProtectedViewContext.Consumer>
);

export default withProtection;
