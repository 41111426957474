import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../../styles/theme';
import VerticalLogoUrl from '../../static/images/vertical-logo.svg';
import FacebookIconUrl from '../../static/icons/icon-facebook.svg';
import InstagramIconUrl from '../../static/icons/icon-instagram.svg';
import { Link } from 'gatsby';
import { CATALOG_BASE_URL } from '../../common';

const HyperlinksContainer = styled.div<ThemedProps>`
    display: flex;
    justify-content: space-between;
    width: 4rem;
    margin: 0.5rem;
`;

const Hyperlink = styled.a<ThemedProps>`
    width: 1.5rem;
    height: 1.5rem;
`;

const FacebookHyperlink = styled(Hyperlink)<ThemedProps>`
    background-image: url(${FacebookIconUrl});
`;

const InstagramHyperlink = styled(Hyperlink)<ThemedProps>`
    background-image: url(${InstagramIconUrl});
`;

const FooterContainer = styled.footer<ThemedProps>`
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10rem;
    border-top: 1px solid ${(props: ThemedProps) => props.theme.colors.gray};
    background-color: ${(props: ThemedProps) => props.theme.colors.white};
`;

const P = styled.p<ThemedProps>`
    color: ${(props: ThemedProps) => props.theme.colors.black};
    font-size: ${(props: ThemedProps) => props.theme.typography.sizes.label};
    margin: 0;
`;

const VerticalLogo = styled(Link)<ThemedProps>`
    background-size: contain;
    background-image: url(${VerticalLogoUrl});
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 15rem;
`;

const Footer: FunctionComponent = () => {
    return (
        <FooterContainer>
            <VerticalLogo to={CATALOG_BASE_URL} />
            <HyperlinksContainer>
                <FacebookHyperlink
                    target="_blank"
                    rel="noopener noreferrer"
                    href={process.env.GATSBY_KING_VAPE_FACEBOOK_URL}
                />
                <InstagramHyperlink
                    target="_blank"
                    rel="noopener noreferrer"
                    href={process.env.GATSBY_KING_VAPE_INSTAGRAM_URL}
                />
            </HyperlinksContainer>
            <P>© since 2020 KING VAPE - Wszelkie prawa zastrzeżone</P>
        </FooterContainer>
    );
};

export default Footer;
