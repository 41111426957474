import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle` 
    html {        
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
    }

    ::-webkit-scrollbar {
        width: 8px;
        background: #e4e4e4;
    }

    ::-webkit-scrollbar-thumb {
        background: #979797;
        border-radius: 15px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #545454;
    }

    body {
        margin: 0;
        overflow: hidden;
        min-height: 100vh;  
        background-color: #F9F9F9;
        background-position: top;
        background-size: auto 80vw;
        background-repeat: repeat;
    }
    
    body:not(.user-is-tabbing) *:focus {
        outline: none;
    }

    * {
        box-sizing: inherit;
        font-family: 'Lato', sans-serif;
        font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-text-size-adjust: 100%;
    }

    *:before {
        box-sizing: inherit;
    }

    *:after {
        box-sizing: inherit;
    }
    
    strong {
        font-weight: bold;
    }
    
    a {
        text-decoration: none;
    }    
        
    h1 {
        font-size: calc(36px + (46 - 36) * ((100vw - 300px) / (1600 - 300)));
    }
    
    h2 {
        font-size: calc(24px + (36 - 24) * ((100vw - 300px) / (1600 - 300)));
    }
    
    h3 {
        font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1600 - 300)));
    }
 
    h4 {
        font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)))
    }
 
`;

export default GlobalStyle;
