import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../../../styles/theme';
import Logo from '../../../static/images/horizontal-logo.svg';
import { Link } from 'gatsby';
import { CATALOG_BASE_URL } from '../../../common';

interface ClickableProps extends ThemedProps {
    clicked: boolean;
}

interface MainContainerProps extends ClickableProps {
    top: number;
    hideOnScroll: boolean;
}

const Container = styled.div<MainContainerProps>`
    display: none;

    ${(props: MainContainerProps) => props.theme.mediaQueries.mobile} {
        display: flex;
        flex-direction: row;
        position: fixed;
        width: 100%;
        padding: 1rem;
        background-color: ${(props: MainContainerProps) => props.theme.colors.white};
        z-index: 6;
        justify-content: space-between;
        transition: top 0.3s ease-in-out;
        top: ${({ hideOnScroll, top }: MainContainerProps) => (hideOnScroll ? '0px' : `${-top}px`)};

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            bottom: 0px;
            background-color: ${(props: MainContainerProps) => props.theme.colors.gray};
            transform: translateX(${({ clicked }: MainContainerProps) => (clicked ? '-100%' : '0%')});
            background-color: ${(props: MainContainerProps) => props.theme.colors.gray};
            transform: translateX(${({ clicked }: MainContainerProps) => (clicked ? '-100%' : '0%')});
            transition: transform 0.3s ease-in-out;
        }
    }
`;

const BurgerContainer = styled.div.attrs({
    tabIndex: 0,
    role: 'button',
})`
    display: flex;
    width: 2rem;
    height: 2rem;
    flex-direction: column;
    justify-content: space-around;
    background: none;
    padding: 0;
    border: none;
    outline: none;
`;

const DefaultBar = styled.div<ThemedProps>`
    width: 100%;
    height: 0.2rem;
    background-color: ${(props: ThemedProps) => props.theme.colors.black};
    border-radius: ${(props: ThemedProps) => props.theme.borders.radius};
    transform-origin: 1px;
    transition: all 0.3s ease-in-out;
`;

const UpperBar = styled(DefaultBar)<ClickableProps>`
    transform: ${(props: ClickableProps) => (props.clicked ? 'rotate(45deg)' : 'rotate(0deg)')};
`;

const MiddleBar = styled(DefaultBar)<ClickableProps>`
    opacity: ${(props: ClickableProps) => (props.clicked ? '0' : '1')};
    transform: ${(props: ClickableProps) => (props.clicked ? 'translateX(100%)' : 'translateX(0)')};
`;

const LowerBar = styled(DefaultBar)<ClickableProps>`
    transform: ${(props: ClickableProps) => (props.clicked ? 'rotate(-45deg)' : 'rotate(0deg)')};
`;

const NavLinkWrapper = styled.span<ClickableProps>`
    opacity: ${({ clicked }: ClickableProps) => (clicked ? '0' : '1')};
    transition: opacity 0.3s ease-in-out;
`;

const NavLinkLogo = styled(Link)`
    display: block;
    background-size: contain;
    width: 10rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${Logo});
`;

interface Props {
    id: string;
    clicked: boolean;
    onClick: () => void;
    top: number;
    hideOnScroll: boolean;
}

const Burger: FunctionComponent<Props> = ({ id, onClick, clicked, top, hideOnScroll }) => (
    <Container id={id} clicked={clicked} top={top} hideOnScroll={hideOnScroll}>
        <NavLinkWrapper clicked={clicked}>
            <NavLinkLogo to={CATALOG_BASE_URL} />
        </NavLinkWrapper>
        <BurgerContainer onClick={onClick}>
            <UpperBar clicked={clicked} />
            <MiddleBar clicked={clicked} />
            <LowerBar clicked={clicked} />
        </BurgerContainer>
    </Container>
);
export default Burger;
