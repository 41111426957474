import { useLayoutEffect, useRef } from 'react';
import { isBrowser } from './utils';

interface CoOrdinates {
    x: number;
    y: number;
}

function getScrollPosition(element?: HTMLElement): CoOrdinates {
    if (!isBrowser) return { x: 0, y: 0 };
    const target = element || document.body;
    const position = target.getBoundingClientRect();
    return { x: position.left, y: position.top };
}

export function useScrollPosition(effect: (prevPos: CoOrdinates, currPos: CoOrdinates) => void): void {
    const position = useRef(getScrollPosition());

    const callBack = (): void => {
        const currPos = getScrollPosition();
        effect(position.current, currPos);
        position.current = currPos;
    };

    useLayoutEffect(() => {
        const handleScroll = (): void => {
            callBack();
        };
        window.addEventListener('scroll', handleScroll);
        return (): void => window.removeEventListener('scroll', handleScroll);
    });
}
