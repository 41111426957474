import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Burger from './burger';
import { ThemedProps } from '../../../styles/theme';
import { CATALOG_BASE_URL, CONTACT_BASE_URL, POSTS_BASE_URL } from '../../../common';
import HoLogo from '../../../static/images/horizontal-logo.svg';
import VeLogo from '../../../static/images/vertical-logo.svg';
import { lockGlobalScroll } from '../../../utils/utils';
import { useScrollPosition } from '../../../utils/useScrollPosition';

interface ClosableProps extends ThemedProps {
    open: boolean;
}

const NavUl = styled.ul<ThemedProps>`
    display: flex;
    flex-direction: row;
    list-style-type: none;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        flex-direction: column;
        padding: 0;
    }
`;

const NavLi = styled.li<ThemedProps>`
    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        padding: 1rem 0;
        margin: 0 auto;
    }
`;

const NavLink = styled(Link)<ThemedProps>`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0 1em;
    color: ${(props: ThemedProps) => props.theme.colors.darkgray};
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: ${(props: ThemedProps) => props.theme.colors.black};
    }

    &.active {
        color: ${(props: ThemedProps) => props.theme.colors.black};
    }

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: calc(-1.5em + 2px);
        height: 4px;
        width: 100%;
        transform: scaleX(0);
        background-color: ${(props: ThemedProps) => props.theme.colors.yellow};
        transition: transform 0.3s ease-in-out;
    }

    &:hover::after,
    &.active::after {
        transform: scaleX(1);
    }
`;

const NavLinkLogo = styled(Link)<ThemedProps>`
    display: block;
    background-image: url(${HoLogo});
    background-position: center;
    background-repeat: no-repeat;
    background-size: no-repeat;
    background-size: contain;
    height: 100%;
    width: 15rem;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        height: 15rem;
        width: 15rem;
        background-image: url(${VeLogo});
    }
`;

const Nav = styled.nav<ClosableProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 4rem;
    padding: 0 20%;
    border: 1px solid ${({ theme }: ClosableProps) => theme.colors.gray};
    background-color: ${({ theme }: ClosableProps) => theme.colors.white};

    ${({ theme }: ClosableProps) => theme.mediaQueries.mobileAndTablets} {
        padding: 0 2rem;
    }

    ${({ theme }: ClosableProps) => theme.mediaQueries.mobile} {
        position: fixed;
        flex-direction: column;
        justify-content: flex-start;
        min-width: 100vw;
        min-height: 100vh;
        padding: 4rem 2rem;
        transform: translateX(${({ open }: ClosableProps) => (open ? '0%' : '100%')});
        font-size: ${({ theme }: ClosableProps) => theme.typography.sizes.title};
        z-index: 5;
        transition: transform 0.3s ease-in-out;
        border: unset;
    }
`;

const NavHeader = styled.header`
    position: relative;
`;

const Navbar: FunctionComponent = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [hideOnScroll, setHideOnScroll] = useState(true);
    const [top, setTop] = useState(0);

    useEffect(() => {
        const element = document.getElementById('burger');
        const top = element?.getBoundingClientRect().height ?? 0;
        setTop(top);
    }, []);

    useScrollPosition((prevPos, currPos) => {
        const isShown = currPos.y > prevPos.y;
        if (isShown !== hideOnScroll) setHideOnScroll(isShown);
    });

    useEffect(() => {
        return () => lockGlobalScroll(false);
    }, []);

    const handleSetIsOpen = () =>
        setIsOpen((state) => {
            lockGlobalScroll(!state);
            return !state;
        });

    return (
        <NavHeader>
            <Burger
                id="burger"
                top={top}
                hideOnScroll={hideOnScroll}
                clicked={isOpen}
                onClick={() => handleSetIsOpen()}
            />
            <Nav open={isOpen}>
                <NavLinkLogo to={CATALOG_BASE_URL} />
                <NavUl>
                    <NavLi>
                        <NavLink to={POSTS_BASE_URL} activeClassName="active">
                            AKTUALNOŚCI
                        </NavLink>
                    </NavLi>
                    <NavLi>
                        <NavLink to={CATALOG_BASE_URL} activeClassName="active">
                            KATALOG
                        </NavLink>
                    </NavLi>
                    <NavLi>
                        <NavLink to={CONTACT_BASE_URL} activeClassName="active">
                            <span>KONTAKT</span>
                        </NavLink>
                    </NavLi>
                </NavUl>
            </Nav>
        </NavHeader>
    );
};

export default Navbar;
