import React, { FunctionComponent, ReactNode } from 'react';
import withProtection from '../../hocs/withProtection';
import Navbar from './navbar/navbar';
import Footer from './footer';
import styled from 'styled-components';
import { ThemedProps } from '../../styles/theme';

const ContentContainer = styled.main<ThemedProps>`
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    min-height: calc(100vh - 4rem - 10rem);

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        min-height: calc(100vh - 10rem);
    }
`;

interface Props {
    children: ReactNode;
}

const Layout: FunctionComponent<Props> = ({ children }) => {
    return (
        <>
            <Navbar />
            <ContentContainer>{children}</ContentContainer>
            <Footer />
        </>
    );
};

export default withProtection(Layout);
