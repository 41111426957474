import { POLISH_CURRENCY } from '../common';

const checkIfProductIsNew = (createdAt: Date): boolean => {
    const dateMonthBefore = new Date();
    dateMonthBefore.setMonth(dateMonthBefore.getMonth() - 1);
    return dateMonthBefore < createdAt;
};

const lockGlobalScroll = (locked: boolean): void => {
    const htmls = Array.from(document.getElementsByTagName('html'));
    htmls.filter((html) => {
        html.style.overflowY = locked ? 'hidden' : 'auto';
    });
};

const formatDate = (date: Date): string => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();

    return `${mm}.${dd}.${yyyy}`;
};

const formatPrice = (price: number): string => {
    const priceInString = price.toFixed(2);
    return `${priceInString}${POLISH_CURRENCY}`;
};

const handleFirstTab = (e: KeyboardEvent): void => {
    if (e.key === 'Tab') {
        document.body.classList.add('user-is-tabbing');

        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
};

const handleMouseDownOnce = (): void => {
    document.body.classList.remove('user-is-tabbing');

    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
};

const isBrowser = typeof window !== 'undefined';

export {
    checkIfProductIsNew,
    lockGlobalScroll,
    formatDate,
    isBrowser,
    handleFirstTab,
    handleMouseDownOnce,
    formatPrice,
};
